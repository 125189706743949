import axios from "axios";
// import store from "./../store";
// import router from "./../router";

// const protocol = process.env.NODE_ENV === "production" ? "https" : "http";
// const port =
//   process.env.NODE_ENV === "production"
//     ? ""
//     : `:${process.env.VUE_APP_API_HOST}`;

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}/api/`,
});

instance.interceptors.request.use(
  function (config) {
    let data = localStorage.getItem("token");

    if (data) {
      config.headers["Authorization"] = data;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.message == "Network Error") {
      alert(
        "Ha ocurrido un error de red, verifique su conexión o recarge la página."
      );
    }

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("REFRESH TOKEN");
      // const access_token = await refreshAccessToken();

      // if (!access_token) {
      // window.location.href = '/sessions/logout'
      // store.dispatch('signOut')
      console.log("ERROR REFRESH");
      // router.push("/session/logout");
      return;
      // }

      // axios.defaults.headers.common["authorization"] = access_token;
      // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // userInfo.token = access_token;
      // localStorage.setItem("userInfo", JSON.stringify(userInfo));

      // return instance(originalRequest);
    }
    return Promise.reject(error);
  }
);

// async function refreshAccessToken() {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await instance.get("users/refresh-token");

//       resolve(response.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

export default instance;
